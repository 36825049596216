<template>
  <div>
    <el-card shadow="never">
      <el-form label-position="right"
               :model="formData"
               ref="feedbackForm"
               label-width="105px"
               element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-row>
          <el-col :span="12">
            <el-form-item label="园区动态标题：" prop="title">
              {{formData.title}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发布者：">
              {{formData.publisher}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-col :span="12">
            <el-form-item label="创建时间：" prop="title">
              {{formData.createTime}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="园区：" prop="type">
              {{formData.parkId}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-col :span="12">
            <el-form-item label="动态封面">
              <img class="img" @click="showImg(formData.cover, '动态封面')" :src="formData.cover" alt="">
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="areaSplit">
          <el-form-item label="资讯内容">
            <div v-html="formData.content"></div>
          </el-form-item>
        </el-row>
        <el-row class="areaSplit">
          <el-button @click="backToList">返回列表</el-button>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {execute} from "./option";
export default {
  name: "parkNewsDetail",
  data() {
    return {
      id: null,
      formData: {}
    }
  },
  created() {
    this.id = this.$route.params.parkNewsId
    this.tryGetPolicyById()
  },
  methods: {
    ...mapActions(['getParkNewsById']),
    tryGetPolicyById() {
      this.getParkNewsById(this.id).then(res => {
        console.log(res)
        this.formData = res
      })
    },
    showImg(imgUrl, title) {
      execute('showImg', {
        imgUrl,
        title
      })
    },
    checkSuccess() {},
    checkFail() {},
    backToList() {
      this.Event.$emit('closeTab')
      this.$router.push({
        name: 'parkNewsList'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.areaSplit {
  border-top: 1px solid #909399;
  padding-top: 15px;
  //max-height: 450px;
  .el-col{
    //width: 100%;
    height: 100%;
    .img{
      width: 100%;
      height: 100%;
      max-height: 400px;
      cursor: pointer;
    }
  }
}
</style>